import {
  GROUPED_OFFER_TYPE_PERCENT,
  GROUPED_OFFER_TYPE_QUANTITY,
  GROUPED_OFFER_TYPE_ABSOLUTE
} from '~/components/features/crud/offers/steps/step2components/Grouped.vue'

export const OFFER_TYPE_REFUND = 'refund'
export const OFFER_TYPE_BONUS = 'bonus'
export const OFFER_TYPE_GROUPED = 'grouped_refund'

export const OFFER_TYPES = [
  OFFER_TYPE_REFUND,
  OFFER_TYPE_BONUS,
  OFFER_TYPE_GROUPED
]

export default {
  useCases: {
    managers: {
      noneFound: 'Aucun administrateur trouvé',

      createNew: 'Créer un nouvel administrateur',
      modalEditTitle: "Modifier l'administrateur",

      success: {
        create: 'Administrateur créé',
        update: 'Administrateur mis à jour'
      }
    },

    offers: {
      createNew: 'Créer une offre',
      selectOrSeachByName: 'Sélectionnez ou recherchez par nom',

      modalTitles: {
        new: 'Créer une offre',
        show: "Détails de l'offre",
        edit: "Édition de l'offre",
        duplicate: "Cpie de l'offre"
      },

      success: {
        new: 'Offre créée',
        edit: 'Offre mise à jour',
        duplicate: 'Offre dupliquée'
      }
    },
    refund_offers: {
      noneFound: 'Aucune offre de remboursement trouvée'
    },
    bonus_offers: {
      noneFound: 'Aucune offre bonus trouvée'
    },
    grouped_offers: {
      noneFound: 'Aucune offre groupée trouvée'
    },

    messages: {
      createNew: 'Créer un nouveau message',
      modalNewTitle: 'Créer un nouveau message',
      modalShowTitle: 'Voir un message',
      modalEditTitle: 'Modifier le message',
      modalDuplicateTitle: 'Dupliquer le message #{id}',
      selectOrSeachByName: 'Sélectionnez ou recherchez par nom',
      noneFound: 'Aucun message trouvé',

      success: {
        create: 'Message créé',
        update: 'Message mis à jour'
      }
    },
    emails: {
      createNew: 'Créer un nouvel email',
      modalNewTitle: 'Créer un nouvel email',
      modalShowTitle: 'Voir un email',
      modalEditTitle: 'Editer un email',
      modalDuplicateTitle: "Dupliquer l'email #{id}",
      selectOrSeachByName: 'Sélectionnez ou recherchez par nom',
      noneFound: 'Aucun mail trouvé',

      success: {
        create: 'Email créé',
        update: 'Email mis à jour'
      },

      sendToUser: {
        modalTitle: 'Envoyer à un utilisateur',
        email: 'email',
        success: 'Email envoyé avec succès'
      }
    },

    segments: {
      notLinkedToAProgram: 'Aucun programme associé à ce segment',
      createNew: 'Créer un nouveau segment',
      isDynamic: 'Ce segment est dynamique',
      selectOrSeachByName: 'Sélectionnez ou recherchez par nom',
      noneFound: 'Aucun segment trouvé',
      none: 'aucun',
      modalShowTitle: 'Segment',
      comment: 'Commentaire:',
      userIn: 'utilisateurs sont inclus dans ce segment',
      createdOn: 'Crée le',
      recordCreated: 'Segment crée avec succès'
    },

    campaigns: {
      createNew: 'Créer une campagne',
      selectOrSeachByName: 'Sélectionnez ou recherchez par nom',
      noneFound: 'Aucune campagne trouvée',

      recommendedPrice: 'Prix conseillé',
      recordCreated: 'Campagne créée avec succès',

      messagePublished: 'Message publié',
      emailPublished: 'Email publié',

      new: {
        dataFilteredBasedOnLoyaltyProgram:
          'données filtrées en fonction du programme',
        dataNotFilteredBasedOnLoyaltyProgram:
          'données non filtrées en fonction du programme',

        youWantTo: 'vous désirez',
        sendAMessage: 'envoyer un message',
        sendAMail: 'envoyer un email',
        distributeAnOffer: 'distribuer une offre',

        youWantToProposeToUsers: 'vous désirez proposer aux utilisateurs',
        aRefundOffer: 'une offre de remboursement',
        aBonusOffer: 'une offre de bonus',
        aGroupedOffer: 'une offre groupée',
        from: 'du',
        to: 'au',
        on: 'le',
        at: 'à',

        tellingThem:
          "en utilisant le contenu du message suivant pour l'annonce en timeline",
        defaultTellingThem:
          "le contenu du message pour l'annonce en timeline sera celui configuré lors de la création de l'offre",
        youWantToSendTheMessage: 'vous désirez envoyer le message',
        youWantToSendTheMail: 'vous désirez envoyer le mail',
        now: 'tout de suite',
        inTheFuture: 'plus tard',

        toUsers: 'aux utilisateurs'
      }
    }
  },

  offers: {
    types: {
      [OFFER_TYPE_REFUND]: 'Remboursement',
      [OFFER_TYPE_BONUS]: 'Bonus',
      [OFFER_TYPE_GROUPED]: 'Remboursement sur plusieurs achats'
    },
    edit: {
      stepper: {
        steps: {
          1: {
            title: 'Nom et Paramètres'
          },
          2: {
            title: 'Données spécifiques',
            titles: {
              [OFFER_TYPE_REFUND]: 'Remboursement et Produits',
              [OFFER_TYPE_BONUS]: 'Mécanisme et Produits',
              [OFFER_TYPE_GROUPED]: 'Volume et Produits'
            }
          },
          3: {
            title: 'Descriptions et Images'
          },
          4: {
            title: 'Récapitulatif'
          }
        }
      },
      alertDeveloperActionNeededForCguAndRegexp:
        "Un développeur est nécessaire pour finaliser l'offre bonus (besoin de créer les CGUs et de remplir `LoyaltyProgramReceiptBonus#matching_regexp`)",
      alertDeveloperActionRegexpIsNotConfigured:
        "Un développeur est nécessaire pour finaliser l'offre bonus : `LoyaltyProgramReceiptBonus#matching_regexp` n'est pas rempli",
      fields: {
        name: {
          label: 'Nom'
        },
        type: {
          label: 'Type'
        },
        program: {
          label: 'Programme',
          scope: {
            open: 'Ouverts',
            closed: 'Fermés'
          }
        },
        description: {
          placeholder: 'Description'
        },
        formattedDescription: {
          label: 'Description formattée (HTML)'
        },
        internalDescription: {
          label: "Description interne (non visible dans l'application)"
        },
        illustration: {
          label: 'Illustration'
        },

        catalogRequiredPoints: {
          name: 'Points requis'
        },
        catalogAvailableDate: {
          label: 'Disponible à partir du',
          placeholder: 'DD/MM/YYYY'
        },
        catalogExpiredDate: {
          label: "N'est plus disponible à partir du",
          placeholder: 'DD/MM/YYYY'
        },

        refundValue: {
          label: 'Montant du remboursement'
        },
        refundType: {
          label: 'Type de remboursement'
        },
        refundBonusPoints: {
          label: 'Nombre (fixe) de points bonus',
          hint: '(ajoutés en plus du remboursement)'
        },

        groupedRefund: {
          type: {
            title: 'Type du remboursement',
            label: {
              [GROUPED_OFFER_TYPE_QUANTITY]:
                'Quantité de produits achetés à rembourser',
              [GROUPED_OFFER_TYPE_PERCENT]:
                "Remboursement d'un pourcentage du montant payé",
              [GROUPED_OFFER_TYPE_ABSOLUTE]:
                "Remboursement d'un montant fixe (en euro)"
            }
          },
          value: {
            label: {
              [GROUPED_OFFER_TYPE_QUANTITY]:
                'Quantité de produits à rembourser',
              [GROUPED_OFFER_TYPE_PERCENT]: 'Montant du remboursement',
              [GROUPED_OFFER_TYPE_ABSOLUTE]: 'Montant du remboursement'
            }
          },
          variants: {
            label: 'Variantes possibles',
            hint: {
              line1: 'Une seule variante est obligatoire.',
              line2:
                'Les variantes supplémentaires permettent de configurer une offre crescendo ; par exemple :',
              example1: '1€ remboursé pour un produit acheté',
              example2: '2€ remboursés pour 2 produits achetés'
            },
            tabs: "Variantes n'%{index}"
          },
          quota: {
            label: 'Quota',
            hint:
              "Nombre de fois maximum que l'utilisateur peut bénéficier de l'offre"
          },
          minItems: {
            label: 'Nombre minimum de produits à acheter',
            hint: '2 achetés 1 offert => 3 produits minimum'
          },
          productsRanges: {
            title: 'Liste des produits et EANs',
            hint: {
              line1: 'Une seule gamme est obligatoire.',
              line2:
                'Les gammes supplémentaires permettent de configurer une offre différenciée.',
              line3:
                'Par exemple : acheter un produit de la gamme mouchoirs en étuis et un produit de la gamme mouchoirs en boîte.'
            },
            tabs: "Gamme n'%{index}"
          }
        },

        bonusType: {
          title: 'Type de bonus',
          label: {
            multiply: 'points multipliés',
            add: 'nombre fixe de points supplémentaires'
          }
        },
        bonusValue: {
          title: 'Valeur du bonus',
          label: {
            multiply: 'par',
            add: 'points supplémentaires'
          }
        },
        bonusMaxQuantity: {
          label: 'À multiplier sur ___ achat(s)',
          hint: '(laisser vide si illimité)'
        },
        bonusMinItems: {
          label: 'Valable à partir de ___ achat(s)'
        },
        // bonusMatchingRegexp: {
        //   label: 'Regexp de correspondance libellé'
        // },
        // bonusExceptionRegexp: {
        //   label: "Regexp d'exception libellé"
        // },

        bonusTitleForWhatNow: {
          label: 'Titre du message de timeline'
        },
        bonusDescriptionForWhatNow: {
          label: 'Description formattée du message de timeline'
        },

        bonusTitleForCoupon: {
          label: "Titre du bon d'achat (et dans la popup)"
        },
        bonusDescriptionForCoupon: {
          label: "Description non formattée du bon d'achat"
        },
        bonusFormattedDescription: {
          label: "Description formattée du bon d'achat"
        },

        // bonusMessageIdentifier: {
        //   label:
        //     'Message utilisé pour afficher des détails dans la popup au clic du What Now ou dans la timeline (app v3)',
        //   hint:
        //     "(peut aussi être utilisé pour envoyer un message dans l'app v2)"
        // },
        bonusDescriptionForUserReview: {
          label:
            "Description du bonus affichée à l'utilisateur dans la revue de ticket"
        },
        bonusProductsEans: {
          labels: {
            fill:
              'Entrez les codes-barre des produits concernés (séparés par des virgules ou des retours à la ligne)',
            show: 'Les codes-barre des produits concernés'
          }
        },

        refundRecommendedPrice: {
          label: 'Prix Public Recommandé'
        },
        refundProductsEans: {
          labels: {
            fill:
              'Entrez les codes-barre des produits concernés (séparés par des virgules ou des retours à la ligne)',
            show: 'Les codes-barre des produits concernés'
          }
        }
      },
      products: {
        verify: 'Vérifier les EANs',
        eansVerification: 'Vérification des EANs',
        someProductsAreMissingYouMustCreateThem:
          'Certains produits sont manquants, à vous de les créer',

        unknownProduct: 'Produit inconnu',
        unknownBrand: 'Marque inconnue',
        imageAlt: 'Image produit',
        mustCreateProduct: 'Vous devez le créer',

        list: {
          item: {
            ean: 'EAN',
            name: 'nom',
            brand: 'marque',
            missingName: 'nom manquant',
            missingBrand: 'marque manquante'
          }
        },

        create: {
          title: 'Créer un produit',
          saved: 'Produit créé'
        },

        update: {
          title: 'Mettre à jour le produit',
          saved: 'Produit mis à jour',

          fields: {
            ean: {
              label: 'EAN'
            },
            name: {
              label: 'Nom'
            },
            nameShort: {
              label: 'Nom court',
              hint: 'Nom/marque abrégé(e) pour la "vue icône"'
            },
            brandName: {
              label: 'Marque',
              hint: 'Commencez à taper le nom de la marque',
              loading: 'Recherche en cours...',
              noData: 'Aucune marque trouvée'
            }
          },

          illustration: {
            current: 'Illustration en cours',
            noCurrent: 'Aucune illustration',

            change: 'La changer avec',
            add: 'En ajouter une avec',

            with: {
              file: 'une image',
              remoteUrl: 'une URL'
            }
          }
        }
      }
    }
  },
  refund: {
    edit: {
      labels: {
        name: 'Nom',
        points: 'Points requis',
        refundValue: 'Montant du remboursement',
        refundType: 'Type de remboursement',
        recommendedPrice: 'Prix Public Recommandé',
        availableDate: 'Disponible à partir du',
        expiredDate: "N'est plus disponible à partir du",
        priority: 'Priorité',

        productsEan: {
          fill:
            'Entrez les codes-barre des produits concernés (séparés par des virgules ou des retours à la ligne)',
          show: 'Les codes-barre des produits concernés'
        },

        internalDescription:
          "Description interne (non visible dans l'application)",
        illustration: 'Illustration',
        background: 'Image de fond'
      },
      placeholders: {
        availableDate: 'DD/MM/YYYY',
        expiredDate: 'DD/MM/YYYY',

        description: 'Description'
      },
      stepper: {
        steps: {
          1: {
            title: 'Nom et Paramètres'
          },
          2: {
            title: 'Produits'
          },
          3: {
            title: 'Descriptions et Images'
          },
          4: {
            title: 'Récapitulatif'
          }
        }
      }
    }
  },
  manager: {
    fields: {
      sendResetEmail: 'Lui envoyer le lien pour changer son mot de passe',
      resetEmailSent: 'Un email a été envoyé à',

      rights: {
        label: "Droits de l'utilisateur",
        hint: 'Séparer par une virgule'
      }
    }
  },
  productsCollection: {
    fields: {
      name: {
        label: 'Nom de la collection'
      },
      tree: {
        label: 'Rechercher un produit'
      },
      availableNodes: {
        label: 'Ajouter des produits',
        hint:
          'Ajouter des noeuds à la collection pour cibler les utilisateurs achetant ces produits'
      }
    },
    new: 'Collection de produit créée',
    update: 'Collection de produits mise à jour',
    noneFound: 'Aucunee collection trouvée'
  },
  messages: {
    types: {
      messages: {
        message: 'Message',
        edit: {
          stepper: {
            steps: {
              1: {
                title: 'Description'
              },
              2: {
                title: 'Notification push'
              },
              3: {
                title: 'Contenu & Image'
              },
              4: {
                title: 'Type & auteur'
              },
              5: {
                title: 'Récapitulatif'
              }
            }
          },
          fields: {
            title: {
              label: 'Titre du message'
            },
            content: {
              label: 'Contenu du message'
            },
            pushNotifTitle: {
              label: 'Titre notification'
            },
            pushNotifImageUrl: {
              label: 'Image dans la notification push',
              hint: "Url de l'image qui va apparaitre dans la notification"
            },
            pushNotifContent: {
              label: 'Contenu de la notification'
            },
            loyaltyProgram: {
              label: 'Programme rattaché'
            },
            collection: {
              label: 'Collection pour action'
            },
            editor: {
              label: 'Partenaire'
            },
            collectionType: {
              label: 'Collection type pour action'
            },
            hightlights: {
              label: 'Push highlights thread'
            },
            internalDescription: {
              label: 'Description interne'
            },
            image: {
              label: 'image dans le message',
              sendAFile: 'envoyer un fichier',
              useAnUrl: 'utiliser une URL',
              imageIsNotSquare:
                "l'image n'est pas carrée, elle va être redimentionnée en carrée, ce qui va la déformer"
            },
            imageUrl: {
              label: 'Url'
            },
            imageHeight: {
              label: "Hauteur de l'image",
              hint: 'pixels'
            },
            action: {
              label: 'action au click du bouton',
              extraInfos:
                "En créant ce message, vous pouvez l'accompagner d'une action lié au bouton. Le message dans le cas d'une V2 se situera dans l'onglet message de l'application. \n Dans le cas d'une V3 le mesage se situera dans la timeline de l'App ",

              associatedTo: {
                label: 'action associée à',

                Collection: 'une collection',
                CollectionType: 'une collection (bis)',
                LoyaltyProgram: 'un programme',
                Quiz: 'un questionnaire',
                Survey: 'une enquête'
              }
            },
            displayedAction: {
              label: 'texte du bouton',
              hint: 'text par défaut si laissé vide : %{text}'
            },

            kind: {
              label: 'Type'
            },
            authorIs: {
              label: "L'auteur est",

              anAdminUser: 'un administrateur',
              anEditor: 'un partenaire',
              aProgram: 'un programme'
            }
          }
        }
      },
      emails: {
        email: 'Email',
        edit: {
          stepper: {
            steps: {
              1: {
                title: 'Général'
              },
              2: {
                title: 'Suivi'
              },
              3: {
                title: 'Entêtes'
              },
              4: {
                title: 'Contenu'
              },
              5: {
                title: 'Récapitulatif'
              }
            }
          },
          fields: {
            behavioral: {
              title: 'Type',
              twoOptions: 'Les emails peuvent être de 2 types :',
              explanationOff:
                "évènementiel : un mail envoyé à chaque fois qu'un évènement particulier se produit (ex: newsletter, quiz, etc.)",
              explanationOn:
                "récurrent : un mail envoyé régulièrement et adapté à chaque utilisateur visé (ex: après la 1ère déclaration de l'utilisateur, ses 1ers points gagnés, ...)",
              labelOff: 'évènementiel',
              labelOn: 'récurrent'
            },
            internalDescription: {
              label: 'Description interne',
              hint:
                'pour décrire ou se référer à ce mail en interne (ne sera pas visible par les utilisateurs)'
            },
            editor: {
              label: 'Partenaire'
            },

            categories: {
              label: 'Catégories',
              hint: 'Séparées par une virgule',
              context:
                'Ajoutez des catégories pour pouvoir retrouver et suivre les stats de cet email. Quelques exemples :',
              example1: 'Lancement du programme Alpro',
              example2: 'Newsletter 25/12/2021 - Cadeaux de nöel',
              example3: "Newsletter 02/05/2022 - Don à l'Ukraine"
            },
            displayedUnsubscribeGroups: {
              label: 'Groupes de désabonnement affichés',
              hint:
                "(Liste des identifiants: séparés par des virgules) (L'utilisateur a un lien dans l'e-mail vers Gérer Préférences et voyez-les ici (L'administrateur peut les modifier dans Sendgrid Suppressions> Groupes de désabonnement)"
            },
            unsubscribeGroup: {
              label: 'Groupe de désabonnement'
            },

            subject: {
              label: 'Sujet / Objet'
            },

            headers: {
              explanation:
                'Pour les adresses des expéditeur et destinataire de réponse, vous devriez utiliser le format suivant : nom à afficher <email>',
              example: 'Exemple : FidMarques <fidmarques@purchease.com>',

              from: 'Expéditeur',
              replyTo: "Destinataire lorsqu'un utilisateur répond au mail"
            },
            from: {
              email: {
                label: 'Email',
                hint: 'no-reply@purchease.com si laissé vide'
              },
              nameToDisplay: {
                label: 'Nom à afficher'
              }
            },
            replyTo: {
              email: {
                label: 'Email',
                hint: "Si laissé vide, la valeur par défaut est l'expéditeur"
              },
              nameToDisplay: {
                label: 'Nom à afficher'
              }
            },

            parametersDescription: {
              explanation:
                "Pour les mails récurrents, à adapter l'utilisateur, merci de décrire le contenu qui est à adapter :"
            },

            content: {
              label: "Contenu de l'email",

              tabs: {
                edit: {
                  icon: 'mdi-pencil',
                  title: 'Édition'
                },
                view: {
                  icon: 'mdi-eye',
                  title: 'Prévisualisation'
                }
              },

              substitutions: {
                title:
                  "Vous pouvez utiliser du texte spécial qui sera remplacé par des données de l'utilisateur",
                explanation:
                  "Utilisez le texte à gauche dans votre mail (tel quel, avec crochets ou accolades), et il sera remplacer par les informations de l'utilisateur (non visible dans la prévisualisation)"
              }
            }
          }
        }
      }
    }
  }
}
