import { flatMap } from 'lodash-es'

function recursiveGetIds(array) {
  return flatMap(array, (node) => {
    const ids = [node.id]
    if (node.children) ids.push(...recursiveGetIds(node.children))
    return ids
  })
}

export const allNodeIds = (state) => {
  return recursiveGetIds(state.treeDetailed)
}

export const hasErrors = (state) => {
  return state.reportsHaveSomeErrors || state.wouldTreeBeModified
}
