<template>
  <div>
    <h4>
      {{ $t('layout.program.importProducts.preview.linesValidation') }}
    </h4>
    <v-alert v-if="reportsHaveSomeErrors" dense type="error">
      {{ $t('layout.program.importProducts.preview.fileHasSomeErrors') }}
      {{
        $t('layout.program.importProducts.preview.youNeedToCorrectThemToImport')
      }}
    </v-alert>
    <v-data-table
      :headers="reportsHeaders"
      :items="reports"
      :items-per-page="itemsPerPage"
    >
      <template #item.ean_detail_i18n="{ item }">
        <td :class="item.ean_css_class">
          {{ item.ean_detail_i18n }}
        </td>
      </template>
      <template #item.brand_detail_i18n="{ item }">
        <td :class="item.brand_css_class">
          {{ item.brand_detail_i18n }}
        </td>
      </template>
    </v-data-table>

    <h4>
      {{
        $t('layout.program.importProducts.preview.productGroupsTreeView.title')
      }}
    </h4>
    <v-alert v-if="wouldTreeBeModified" dense type="error">
      {{
        $t(
          'layout.program.importProducts.preview.productGroupsTreeView.alert.impossibleImportBecauseProductGroupTreeWouldBeModified'
        )
      }}
      <br />
      {{
        $t(
          'layout.program.importProducts.preview.productGroupsTreeView.alert.youNeedToEditTheProductGroupTreeYourselfInOrderToImportProducts'
        )
      }}
      :
      <nuxt-link icon to="/sources/products/tree">
        <v-icon>mdi-file-tree</v-icon>
      </nuxt-link>
    </v-alert>
    <v-treeview hoverable dense :items="treeDetailed" :open="openNodes">
      <template #prepend="{ item, open }">
        <v-icon v-if="item.is_group">
          {{ open ? 'mdi-folder-open' : 'mdi-folder' }}
        </v-icon>
        <v-icon v-else>
          mdi-cart
        </v-icon>
      </template>
      <template #label="{ item }">
        <div :class="item.css_class">
          {{ item.name }}
        </div>
      </template>
      <template #append="{ item }">
        <div :class="item.css_class">
          {{ item.detail_i18n }}
        </div>
      </template>
    </v-treeview>

    <v-card-actions>
      <v-spacer />
      <v-btn text :disabled="isSending" @click="$emit('go-to-prev-step')">
        {{ $t('global.previous') }}
      </v-btn>

      <v-tooltip top :disabled="!hasErrors">
        <template #activator="{ attrs, on }">
          <div class="d-inline-block" v-bind="attrs" v-on="on">
            <v-btn
              :disabled="hasErrors"
              color="primary"
              :loading="isSending"
              @click="submit"
            >
              {{ $t('layout.program.importProducts.import') }}
            </v-btn>
          </div>
        </template>
        <span>
          {{ $t('layout.program.importProducts.preview.fileHasSomeErrors') }}
          {{
            $t(
              'layout.program.importProducts.preview.youNeedToCorrectThemToImport'
            )
          }}
        </span>
      </v-tooltip>
    </v-card-actions>
  </div>
</template>

<script>
import { mapState, mapGetters, mapMutations } from 'vuex'

import toFormData from '~/utils/functions/toFormData'
import { fetchCoreFromComponentAndHandleError } from '~/utils/functions/fetch-and-handle-errors'

export default {
  data: () => ({
    itemsPerPage: -1,
    isSending: false,
    openNodes: []
  }),
  computed: {
    ...mapState('program/import-file-with-eans', [
      'file',

      'reportsHeaders',
      'reports',
      'reportsHaveSomeErrors',

      'treeDetailed',
      'wouldTreeBeModified'
    ]),
    ...mapGetters('program/import-file-with-eans', ['allNodeIds', 'hasErrors'])
  },
  watch: {
    treeDetailed: {
      immediate: true,
      deep: true,
      handler() {
        this.openNodes = this.allNodeIds.concat()
      }
    }
  },
  methods: {
    ...mapMutations('snackbar', ['addToast']),

    submit() {
      return fetchCoreFromComponentAndHandleError({
        $vm: this,
        isLoadingKey: 'isSending',
        errorInToast: true,

        axios: {
          method: 'POST',
          url: `/programs/${this.$route.params.programIdentifier}/import_file_with_eans`,
          data: toFormData({ file: this.file })
        },

        onSuccess: () => {
          this.addToast({
            text: this.$t('layout.program.importProducts.importSuccessful')
          })
          this.$emit('close')
        }
      })
    }
  }
}
</script>
