export default () => ({
  file: null,

  reports: [],
  reportsHeaders: [],
  reportsHaveSomeErrors: null,

  treeDetailed: [],
  wouldTreeBeModified: null
})
