import { VAlert } from 'vuetify/lib/components/VAlert';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTooltip } from 'vuetify/lib/components/VTooltip';
import { VTreeview } from 'vuetify/lib/components/VTreeview';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('h4',[_vm._v("\n    "+_vm._s(_vm.$t('layout.program.importProducts.preview.linesValidation'))+"\n  ")]),_vm._v(" "),(_vm.reportsHaveSomeErrors)?_c(VAlert,{attrs:{"dense":"","type":"error"}},[_vm._v("\n    "+_vm._s(_vm.$t('layout.program.importProducts.preview.fileHasSomeErrors'))+"\n    "+_vm._s(_vm.$t('layout.program.importProducts.preview.youNeedToCorrectThemToImport'))+"\n  ")]):_vm._e(),_vm._v(" "),_c(VDataTable,{attrs:{"headers":_vm.reportsHeaders,"items":_vm.reports,"items-per-page":_vm.itemsPerPage},scopedSlots:_vm._u([{key:"item.ean_detail_i18n",fn:function(ref){
var item = ref.item;
return [_c('td',{class:item.ean_css_class},[_vm._v("\n        "+_vm._s(item.ean_detail_i18n)+"\n      ")])]}},{key:"item.brand_detail_i18n",fn:function(ref){
var item = ref.item;
return [_c('td',{class:item.brand_css_class},[_vm._v("\n        "+_vm._s(item.brand_detail_i18n)+"\n      ")])]}}])}),_vm._v(" "),_c('h4',[_vm._v("\n    "+_vm._s(_vm.$t('layout.program.importProducts.preview.productGroupsTreeView.title'))+"\n  ")]),_vm._v(" "),(_vm.wouldTreeBeModified)?_c(VAlert,{attrs:{"dense":"","type":"error"}},[_vm._v("\n    "+_vm._s(_vm.$t(
        'layout.program.importProducts.preview.productGroupsTreeView.alert.impossibleImportBecauseProductGroupTreeWouldBeModified'
      ))+"\n    "),_c('br'),_vm._v("\n    "+_vm._s(_vm.$t(
        'layout.program.importProducts.preview.productGroupsTreeView.alert.youNeedToEditTheProductGroupTreeYourselfInOrderToImportProducts'
      ))+"\n    :\n    "),_c('nuxt-link',{attrs:{"icon":"","to":"/sources/products/tree"}},[_c(VIcon,[_vm._v("mdi-file-tree")])],1)],1):_vm._e(),_vm._v(" "),_c(VTreeview,{attrs:{"hoverable":"","dense":"","items":_vm.treeDetailed,"open":_vm.openNodes},scopedSlots:_vm._u([{key:"prepend",fn:function(ref){
      var item = ref.item;
      var open = ref.open;
return [(item.is_group)?_c(VIcon,[_vm._v("\n        "+_vm._s(open ? 'mdi-folder-open' : 'mdi-folder')+"\n      ")]):_c(VIcon,[_vm._v("\n        mdi-cart\n      ")])]}},{key:"label",fn:function(ref){
      var item = ref.item;
return [_c('div',{class:item.css_class},[_vm._v("\n        "+_vm._s(item.name)+"\n      ")])]}},{key:"append",fn:function(ref){
      var item = ref.item;
return [_c('div',{class:item.css_class},[_vm._v("\n        "+_vm._s(item.detail_i18n)+"\n      ")])]}}])}),_vm._v(" "),_c(VCardActions,[_c(VSpacer),_vm._v(" "),_c(VBtn,{attrs:{"text":"","disabled":_vm.isSending},on:{"click":function($event){return _vm.$emit('go-to-prev-step')}}},[_vm._v("\n      "+_vm._s(_vm.$t('global.previous'))+"\n    ")]),_vm._v(" "),_c(VTooltip,{attrs:{"top":"","disabled":!_vm.hasErrors},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var attrs = ref.attrs;
      var on = ref.on;
return [_c('div',_vm._g(_vm._b({staticClass:"d-inline-block"},'div',attrs,false),on),[_c(VBtn,{attrs:{"disabled":_vm.hasErrors,"color":"primary","loading":_vm.isSending},on:{"click":_vm.submit}},[_vm._v("\n            "+_vm._s(_vm.$t('layout.program.importProducts.import'))+"\n          ")])],1)]}}])},[_vm._v(" "),_c('span',[_vm._v("\n        "+_vm._s(_vm.$t('layout.program.importProducts.preview.fileHasSomeErrors'))+"\n        "+_vm._s(_vm.$t(
            'layout.program.importProducts.preview.youNeedToCorrectThemToImport'
          ))+"\n      ")])])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }