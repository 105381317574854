






















































import Vue, { PropType } from 'vue'

import DeclarationZoom from './DeclarationZoom.vue'
import hasOwnProp from '~/utils/functions/hasOwnProp'

/* eslint-disable camelcase */
export interface Subpicture {
  identifier: string
  rank: string
  pdf_url: string
  images: Image[]
}
export interface Image {
  name_short: string
  name_long_i18n: string
  url: string
}
/* eslint-enable camelcase */

export default Vue.extend({
  components: { DeclarationZoom },
  props: {
    subpicture: {
      type: Object as PropType<Subpicture>,
      required: true,
      validator: (o: Subpicture) =>
        hasOwnProp(o, 'identifier') &&
        hasOwnProp(o, 'rank') &&
        hasOwnProp(o, 'images') &&
        hasOwnProp(o, 'pdf_url') &&
        true
    },
    multiple: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    tab: 0,
    angle: 0
  }),
  computed: {
    selectedImage(): Image {
      return this.subpicture.images[this.tab]
    },
    title(): string {
      if (!this.multiple) {
        if (this.selectedImage) {
          return this.selectedImage.name_long_i18n
        } else if (this.subpicture.pdf_url) {
          return this.$t('pages.user.declaration.originalPdf')
        }
      }

      return this.$t('pages.user.declaration.subpictureImageTitle', {
        rank: this.subpicture.rank
      }) as string
    }
  },
  methods: {
    imageAlt(image: Image): string {
      return this.$t('pages.user.declaration.subpictureImageAlt', {
        rank: this.subpicture.rank,
        name: image.name_long_i18n
      }) as string
    },
    rotate() {
      this.angle += 90
    },
    setAngle(angle: number) {
      this.angle = angle
    }
  }
})
