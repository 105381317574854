<template>
  <v-toolbar
    short
    extended
    :color="paletteScanobar.black"
    dark
    class="v-toolbar--mini-padding-top"
    :height="height"
  >
    <!-- <template #img="{ props }">
      <v-img
        v-bind="props"
        gradient="to top right, rgba(100,115,201,.7), rgba(25,32,72,.7)"
      />
    </template> -->

    <!-- <v-app-bar-nav-icon/ > -->

    <v-toolbar-title class="flex-grow-1">
      <DisplayErrorV2
        v-if="error"
        :error="error"
        retriable
        @retry="
          fetchProgramByIdentifier({
            programIdentifier: $route.params.programIdentifier
          })
        "
      />

      <v-skeleton-loader v-else-if="isLoading" type="heading" width="500" />

      <div
        v-else-if="isLoaded"
        class="d-flex justify-space-between align-center"
      >
        <div>
          {{ program.name }}
        </div>

        <!-- <WarningIfProgramHasNoPeriod
          v-if="!isManager && program && !program.is_child"
        /> -->

        <DialogImportEans />
      </div>
    </v-toolbar-title>

    <template #extension>
      <v-tabs align-with-title>
        <!-- fake tab for pages not in the tab list (ex: periods_new) -->
        <v-tab class="pa-0" style="min-width:0px" />

        <v-tab
          v-for="tab in tabs"
          :key="tab.identifier"
          nuxt
          :to="tab.to"
          v-text="tab.title"
        />
      </v-tabs>
    </template>
  </v-toolbar>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex'

import DisplayErrorV2 from '@/components/layouts/DisplayErrorV2'
import DialogImportEans from '@/components/features/program/import-file-with-eans/dialog.vue'
// import WarningIfProgramHasNoPeriod from '@/components/layouts/WarningIfProgramHasNoPeriod.vue'

import MixinPaletteScanobar from '@/mixins/paletteScanobar'

import {
  PROGRAM_KIND_POINTS_VIA_PRICE,
  PROGRAM_KIND_STAMPS,
  PROGRAM_KIND_PUZZLE,
  // PROGRAM_KIND_COLLECTOR,
  PROGRAM_KIND_FIDZ
} from '~/store/program/state.js'

export const TAB_PERIODS = 'periods'
export const TAB_PERIODS_NEW = 'periods_new'
export const TAB_CATALOG = 'catalog'
export const TAB_DESCRIPTIONS = 'descriptions'
export const TAB_ASSETS = 'assets'
export const TAB_COLOR = 'color'
export const TAB_PRECISION = 'precisions'
export const TAB_BRANDS = 'brands'
export const TAB_VISIBILITY = 'visibility'
export const TAB_APPLET = 'applet'

export const TAB_POINTS = 'points'
export const TAB_STAMPS = 'stamps'
export const TAB_PUZZLE = 'puzzle'

export default {
  components: {
    // WarningIfProgramHasNoPeriod,
    DisplayErrorV2,
    DialogImportEans
  },
  mixins: [MixinPaletteScanobar],
  props: {
    height: {
      type: Number,
      default: undefined
    }
  },
  computed: {
    ...mapState('auth', ['selectedProgram']),
    ...mapGetters('auth', ['isManager']),

    ...mapGetters('program', ['routeBasePath']),

    ...mapState('program', ['isLoading', 'isLoaded', 'error', 'program']),

    tabsForProgramAsChild() {
      return this.exportTabs([TAB_COLOR, TAB_ASSETS])
    },
    tabsForProgramGeneric() {
      return this.exportTabs(
        this.tabsBeforeSpecificTab.concat(this.tabsAfterSpecificTab)
      )
    },
    tabsForProgramWithFidz() {
      return this.exportTabs([TAB_CATALOG])
    },
    tabsForProgramGenericAndSpecificTab() {
      return this.exportTabs(
        this.tabsBeforeSpecificTab
          .concat([this.tabSpecific])
          .concat(this.tabsAfterSpecificTab)
      )
    },
    tabsBeforeSpecificTab() {
      return [
        TAB_COLOR,
        TAB_ASSETS,
        TAB_DESCRIPTIONS,
        TAB_CATALOG,
        TAB_PRECISION,
        TAB_BRANDS
      ]
    },
    tabsAfterSpecificTab() {
      if (!this.selectedProgram) {
        return [TAB_VISIBILITY, TAB_APPLET]
      }

      switch (this.selectedProgram.is_core_the_source_of_truth_for_analytics) {
        case null:
          return [TAB_VISIBILITY, TAB_APPLET]
        case false:
          return [TAB_PERIODS, TAB_VISIBILITY, TAB_APPLET]
        case true:
          return [TAB_PERIODS_NEW, TAB_VISIBILITY, TAB_APPLET]
      }

      return [TAB_VISIBILITY, TAB_APPLET]
    },
    tabs() {
      // can happen with a direct link to a program
      // ex : /programs/management/loyalty-program-190/periods
      if (!this.program) {
        return this.tabsForProgramGeneric
      }

      if (this.program.is_child) {
        return this.tabsForProgramAsChild
      }

      switch (this.program.kind) {
        case PROGRAM_KIND_POINTS_VIA_PRICE:
        case PROGRAM_KIND_STAMPS:
        case PROGRAM_KIND_PUZZLE:
          return this.tabsForProgramGenericAndSpecificTab
        case PROGRAM_KIND_FIDZ:
          return this.tabsForProgramWithFidz
        default:
          return this.tabsForProgramGeneric
      }
    },
    tabSpecific() {
      // can happen with a direct link to a program
      // ex : /programs/management/loyalty-program-190/periods
      if (!this.program) {
        return null
      }

      if (this.program.is_child) {
        return null
      }

      switch (this.program.kind) {
        case PROGRAM_KIND_POINTS_VIA_PRICE:
          return TAB_POINTS
        case PROGRAM_KIND_STAMPS:
          return TAB_STAMPS
        case PROGRAM_KIND_PUZZLE:
          return TAB_PUZZLE
        // case PROGRAM_KIND_FIDZ:
        //   return this.tabsForProgramWithFidz
        // default:
        //   return this.tabsForProgramGeneric
      }
      return null
    }
  },

  methods: {
    ...mapActions('program', ['fetchProgramByIdentifier']),

    exportTabs(tabs) {
      return tabs.map((tab) => this.exportTab(tab))
    },
    exportTab(tab) {
      return {
        identifier: tab,
        title: this.$t(`layout.program.tabs.${tab}`),
        to: `${this.routeBasePath}/${tab}`
      }
    }
  }
}
</script>

<style lang="scss" scoped>
// divider below drawer logo and app bar
// are not aligned without this
.v-toolbar--mini-padding-top {
  margin-top: 1px;
}
</style>
